import React from 'react'
import Layout from '../Layout'
import Home from '../components/home'
import SEO from '../components/seo'

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="M&S Reliable Construction | Reliable Renovation Contractors in Vancouver"
        description="Renovation Services provided by M&S Reliable Construction in British Columbia."
        lang="en"
      />
      <Home />
    </Layout>
  )
}

export default IndexPage
