import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { Link } from 'gatsby'

export const ServicesPreview = ({ data }) => {
  const styles = {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    opacity: '0.6',
  }

  console.log({ data })

  return (
    <StyledServices>
      <h2>View our Services</h2>
      <StyledServicesPreviewGrid>
        <StyledProject>
          <ServicesPreviewInfo title={'Flooring'} description={'This is project 1'} to="/services/#Flooring" />
          <Img fluid={data?.flooringImage?.childImageSharp?.fluid} style={styles} />
        </StyledProject>
        <StyledProject>
          <ServicesPreviewInfo title={'Tiling'} description={'This is project 2'} to="/services/#Tiling" />
          <Img fluid={data?.tilingImage?.childImageSharp?.fluid} style={styles} />
        </StyledProject>
        <StyledProject>
          <ServicesPreviewInfo title={'Painting'} description={'This is project 3'} to="/services/#Painting" />
          <Img fluid={data?.paintingImage?.childImageSharp?.fluid} style={styles} />
        </StyledProject>
        <StyledProject>
          <ServicesPreviewInfo title={'Dry Wall'} description={'This is project 4'} to="/services/#Dry%20Wall" />
          <Img fluid={data?.drywallImage?.childImageSharp?.fluid} style={styles} />
        </StyledProject>
        <StyledProject>
          <ServicesPreviewInfo title={'Framing'} description={'This is project 6'} to="/services/#Framing" />
          <Img fluid={data?.framingAfterImage?.childImageSharp?.fluid} style={styles} />
        </StyledProject>
        <StyledProject>
          <ServicesPreviewInfo title={'Masonry'} description={'This is project 5'} to="/services/#Masonry" />
          <Img fluid={data?.masonryImage?.childImageSharp?.fluid} style={styles} />
        </StyledProject>
      </StyledServicesPreviewGrid>
    </StyledServices>
  )
}

const StyledServices = styled.section`
  display: flex;
  grid-column: 1 / 4;
  flex-direction: column;
  width: 100%;
  padding-top: 0rem;

  h2 {
    text-align: center;
    padding: 2rem;
  }
`

const StyledServicesPreviewGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(481px, 1fr));
  grid-template-rows: auto;
  background-color: var(--text);
  background-color: var(--primary);

  @media (max-width: 485px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`

const StyledProject = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  max-height: 500px;
`

const ServicesPreviewInfo = ({ title, description, company, to }) => {
  return (
    <StyledServicesPreviewInfo to={to}>
      <div>
        <h3>{title}</h3>
        {/* <p>{description}</p> */}
        <small>{company}</small>
      </div>
    </StyledServicesPreviewInfo>
  )
}

const StyledServicesPreviewInfo = styled(Link)`
  position: absolute;
  z-index: 6;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  cursor: pointer;
  /* backdrop-filter: blur(7px); */

  h3,
  p,
  small {
    color: var(--text);
  }
`
