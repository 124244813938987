import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

export const Featurette = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Home_Renovations_(Unsplash).jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reliableImage: file(relativePath: { eq: "reliable.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      professionalImage: file(relativePath: { eq: "professional.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      transparentImage: file(relativePath: { eq: "transparent.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledFeaturette>
      <h2>{title}</h2>
      <div className="card-flex">
        <Card
          image={data?.professionalImage?.childImageSharp.fluid}
          copy={'Years of experience working with clients all across the lower mainland.'}
          title={'Professional'}
        />
        <Card
          image={data?.transparentImage?.childImageSharp.fluid}
          copy={'We are upfront and honest about our pricing.'}
          title={'Transparent'}
        />
        <Card image={data?.reliableImage?.childImageSharp.fluid} copy={`It's in our name.`} title={'Reliable'} />
      </div>
    </StyledFeaturette>
  )
}

const StyledFeaturette = styled.section`
  display: flex;
  flex-direction: column;
  grid-column: 2 / 3;
  width: 100%;
  padding: 4rem;

  h2 {
    text-align: center;
    padding: 1rem;
  }

  .card-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: inherit;
  }

  @media (max-width: 692px) {
    padding: 1rem;
  }
`

const Card = ({ image, copy, title }) => {
  const styles = {
    gridColumn: '1 / 5',
    width: '100%',
    height: '100%',
    // filter: 'blur(24px)',
    filter: 'opacity(75%)',
    borderRadius: '100%',
  }

  return (
    <StyledCard>
      <div>
        <Img fluid={image} style={styles} />
      </div>
      <h3>{title}</h3>
      <p>{copy}</p>
    </StyledCard>
  )
}

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  padding: 1rem;
  flex: 1;

  div {
    width: 250px;
    height: 250px;
  }

  h3 {
    padding: 1rem;
    text-align: center;
    width: 100%;
  }

  p {
    padding: 0 1rem;
    text-align: center;
  }
`
