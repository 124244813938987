import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

export const CTA = ({ copy }) => {
  return (
    <StyledCTA to="/contact/">
      <button>{copy}</button>
    </StyledCTA>
  )
}

const StyledCTA = styled(Link)`
  button {
    border: none;
    box-shadow: none;
    text-decoration: none;
    padding: 8px 12px;
    margin-top: 2rem;
    background-color: var(--brand);
    color: var(--primary);
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.8rem;
    line-height: 1;
    text-align: center;
    width: 100%;
  }
`
