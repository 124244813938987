import React, { FC } from 'react'
import { Landing } from '../landing'
import { ServicesPreview } from '../servicesPreview'
import { Testimonials } from '../testimonials'
import { useStaticQuery, graphql } from 'gatsby'
import { Info } from '../info'
import { CTA } from '../CTA'
import { Featurette } from '../featurette'
import { Split } from '../split'

const Home: FC = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Home_Renovations_(Unsplash).jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fireplaceImage: file(relativePath: { eq: "fireplace.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      flooringImage: file(relativePath: { eq: "flooring.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      masonryImage: file(relativePath: { eq: "masonry.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paintingImage: file(relativePath: { eq: "painting.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tilingImage: file(relativePath: { eq: "tiling.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drywallImage: file(relativePath: { eq: "drywall-after.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      framingAfterImage: file(relativePath: { eq: "framing-after.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kitchenAfterImage: file(relativePath: { eq: "kitchen-after.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kitchenBeforeImage: file(relativePath: { eq: "kitchen-before.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Landing
        copy={
          <h1>
            Reliable <br /> Renovation <br />
            Contractors
          </h1>
        }
        data={data?.placeholderImage?.childImageSharp?.fluid}
      />
      <Info
        title={
          <h2>
            Vancouver <br />
            Bathroom
            <br /> Renovations
            <CTA copy={'Get a Quote'} />
          </h2>
        }
        copy={
          <p>
            Hire reliable bathroom renovation experts who you can trust. Our home building services are available
            throughout Greater Vancouver. We specialize in finish such as flooring, tiling, painting, drywall, masonry
            and mudding.
          </p>
        }
      />

      <Featurette title={'Why Choose M&S Reliable Construction'} />

      <ServicesPreview data={data} />

      <Testimonials />
    </>
  )
}

export default Home
